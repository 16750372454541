// Editor.jsx
import React, { useRef, useState, useCallback } from 'react';
import JoditEditor from 'jodit-react';
import { editorConfig } from '../../helpers/template';
import useDebouncedCallback from '../../helpers/useDebouncedCallback'; // Adjust the path as needed

const Editor = React.memo(({ subject, body, index, handleChange }) => {
  const editorRef = useRef(null);

  // Local state to avoid updating the parent on every keystroke
  const [localSubject, setLocalSubject] = useState(subject || '');
  const [localBody, setLocalBody] = useState(body || '');

  // Create debounced callbacks (300ms delay)
  const debouncedSubjectChange = useDebouncedCallback((newValue, idx) => {
    // Create a fake event matching parent's expected signature
    handleChange({ target: { name: 'subject', value: newValue } }, idx);
  }, 300);

  const debouncedBodyChange = useDebouncedCallback((newContent, idx) => {
    handleChange({ target: { name: 'body', value: newContent } }, idx);
  }, 300);

  // Handler for subject input
  const onSubjectChange = useCallback(
    e => {
      const newValue = e.target.value;
      setLocalSubject(newValue);
      debouncedSubjectChange(newValue, index);
    },
    [debouncedSubjectChange, index]
  );

  // Handler for JoditEditor blur event
  const onEditorBlur = useCallback(
    newContent => {
      setLocalBody(newContent);
      debouncedBodyChange(newContent, index);
    },
    [debouncedBodyChange, index]
  );

  return (
    <>
      <div className='form-group'>
        <label>Subject:</label>
        <input
          name='subject'
          className='form-control'
          value={localSubject}
          onChange={onSubjectChange}
        />
      </div>
      <JoditEditor
        name='body'
        ref={editorRef}
        value={localBody}
        config={editorConfig(false)}
        tabIndex={1}
        onBlur={onEditorBlur}
      />
    </>
  );
});

export default Editor;
