import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { generateFoodList } from '../../../helpers/dailyLog';
import { orderBy } from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from '../../common/DatePicker';
import { createDailyLog, deleteDailyLog } from '../../../actions/dailyLog';
import QuantityModal from './QuantityModal';
import { toDate } from 'date-fns-tz';
import DailyLogCard from './DailyLogCard';
import {
  DEFAULT_LOG_ENTRY_FORM_STATE,
  PRIMARY_COLOR
} from '../../../constants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const LogsEntryModal = props => {
  const {
    clinicId,
    csrfToken,
    refreshPatient,
    setShow,
    userWlProgram,
    isAllLogs = false,
    handleClose = () => {},
    show // controlled from parent if needed
  } = props;

  // --- Component State ---
  // Renamed "additionalFood" to "additionalNotes"
  const [formState, setFormState] = useState(() => {
    const todayDate = new Date();
    return {
      ...DEFAULT_LOG_ENTRY_FORM_STATE,
      additionalNotes: '',
      date:
        todayDate >= new Date(userWlProgram?.start_date) &&
        todayDate <= new Date(userWlProgram?.end_date)
          ? todayDate
          : new Date(userWlProgram?.end_date)
    };
  });
  const [options, setOptions] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedDailyLog, setSelectedDailyLog] = useState(0);
  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
  const [selectedFood, setSelectedFood] = useState({});

  // New states for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [logToDelete, setLogToDelete] = useState(null);

  // Derive the selected options for quantity modal if needed
  const key = Object.keys(selectedFood);
  let selectedOptions = [];
  if (key.length > 0) {
    selectedOptions = formState[key][selectedFood[key]];
  }

  // --- Helper Functions ---
  const clearForm = () => {
    const todayDate = new Date();
    setFormState({
      ...DEFAULT_LOG_ENTRY_FORM_STATE,
      additionalNotes: '',
      date:
        todayDate >= new Date(userWlProgram?.start_date) &&
        todayDate <= new Date(userWlProgram?.end_date)
          ? todayDate
          : new Date(userWlProgram?.end_date)
    });
  };

  const currentPhase = useMemo(() => {
    if (!userWlProgram) return;
    let start = new Date(userWlProgram.start_date);
    let today = new Date(formState.date);
    let diff = Math.round((today - start) / (1000 * 60 * 60 * 24)) + 1;
    const { phases } = userWlProgram.weight_loss_program;
    let totalDays = 0;
    let current_phase = 0;
    for (let i = 0; i < phases?.length; i++) {
      totalDays += phases[i].days;
      current_phase = i;
      if (diff <= totalDays) break;
    }
    return phases[current_phase];
  }, [formState.date, userWlProgram]);

  useEffect(() => {
    if (userWlProgram) {
      setOptions(generateFoodList(userWlProgram, formState.date));
    }
  }, [formState.date, userWlProgram]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const foodSpliter = foodList => {
    const { meals, snacks } = foodList;
    const mData = {};
    const sData = {};
    Object.values(meals).forEach((meal, index) => {
      mData[index] = meal.map(food => {
        return { label: food, value: food.split('-')[1] };
      });
    });
    Object.values(snacks).forEach((snack, index) => {
      sData[index] = snack.map(food => {
        return { label: food, value: food.split('-')[1] };
      });
    });
    return { meals: mData, snacks: sData };
  };

  // extractFoodLabel returns the labels as entered.
  const extractFoodLabel = () => {
    const meals = {};
    const snacks = {};

    for (const [key, value] of Object.entries(formState.meals)) {
      meals[key] = value.map(option => option.label);
    }
    for (const [key, value] of Object.entries(formState.snacks)) {
      snacks[key] = value.map(option => option.label);
    }
    return { meals, snacks };
  };

  const handleDailyLog = () => {
    const { meals, snacks } = extractFoodLabel();
    // Include additionalNotes in the payload as additional_notes.
    const dailyLog = {
      date: formState.date.toString(),
      weight: formState.weight,
      water_intake: formState.waterIntake,
      sleeping_hours: formState.sleepingHours,
      blood_sugar: formState.bloodSugar,
      blood_pressure: formState.bloodPressure,
      meals,
      snacks,
      additional_notes: formState.additionalNotes
    };
    const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs`;
    createDailyLog(dailyLog, csrfToken, url, axios.post, refreshPatient);
    setShow(false);
    clearForm();
  };

  const handleEdit = () => {
    const { meals, snacks } = extractFoodLabel();
    const dailyLog = {
      date: formState.date.toString(),
      weight: formState.weight,
      water_intake: formState.waterIntake,
      sleeping_hours: formState.sleepingHours,
      blood_sugar: formState.bloodSugar,
      blood_pressure: formState.bloodPressure,
      meals,
      snacks,
      additional_notes: formState.additionalNotes
    };
    const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs/${selectedDailyLog.id}`;
    createDailyLog(dailyLog, csrfToken, url, axios.patch, refreshPatient);
    setShowEdit(false);
    clearForm();
  };

  const addQuantity = quantity => {
    const index = selectedOptions.length - 1;
    const copyOption = [...selectedOptions];
    copyOption[index].label = `${quantity}x-${copyOption[index].label}`;
    setFormState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [selectedFood[key]]: [...copyOption]
      }
    }));
    toggleQuantityModal();
  };

  const handleFoodIntake = (e, action, foodNumber, isMeal) => {
    const intakeItem = isMeal ? 'meals' : 'snacks';
    setSelectedFood({ [intakeItem]: foodNumber });
    if (action.action !== 'remove-value') {
      toggleQuantityModal();
    }
    setFormState(prevState => ({
      ...prevState,
      [intakeItem]: {
        ...prevState[intakeItem],
        [foodNumber]: e
      }
    }));
  };

  const handleSelectedLog = dailyLog => {
    setFormState({
      ...DEFAULT_LOG_ENTRY_FORM_STATE,
      additionalNotes: '',
      date: toDate(dailyLog.date),
      weight: dailyLog.weight,
      waterIntake: dailyLog.water_intake,
      sleepingHours: dailyLog.sleeping_hours,
      bloodSugar: dailyLog.blood_sugar,
      bloodPressure: dailyLog.blood_pressure
    });
    setSelectedDailyLog(dailyLog);
    setFormState(prevState => ({ ...prevState, ...foodSpliter(dailyLog) }));
    setOptions(generateFoodList(userWlProgram, new Date(dailyLog.date)));
  };

  // --- Deletion Confirmation ---
  const handleDelete = id => {
    setLogToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (logToDelete) {
      const url = `/${clinicId}/user_wl_program/${userWlProgram?.id}/daily_logs/${logToDelete}`;
      deleteDailyLog(url, refreshPatient, csrfToken);
      setShowDeleteModal(false);
      setLogToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setLogToDelete(null);
  };

  const toggleQuantityModal = () => {
    setIsQuantityModalOpen(!isQuantityModalOpen);
  };

  const handleFocus = () => {
    setOptions(generateFoodList(userWlProgram, formState.date));
  };

  const closeModal = () => {
    clearForm();
    if (isAllLogs) {
      setShowEdit(false);
      handleClose();
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <Modal
        show={show || isAllLogs}
        onHide={closeModal}
        backdrop='static'
        keyboard={false}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-5'>
            {`Weight Loss Program: ${userWlProgram?.weight_loss_program?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showEdit && isAllLogs ? (
            orderBy(userWlProgram?.daily_logs, ['date'], ['desc']).map(
              (dailyLog, key) => (
                <DailyLogCard
                  dailyLog={dailyLog}
                  key={`daily-log-${key}`}
                  open={key === 0}
                  setShowEdit={setShowEdit}
                  handleSelectedLog={handleSelectedLog}
                  handleDelete={handleDelete}
                />
              )
            )
          ) : (
            <>
              <div className='form-group mb-3'>
                <label htmlFor='date'>Date:</label>
                <DatePicker
                  date={formState.date}
                  setDate={value =>
                    setFormState(prevState => ({ ...prevState, date: value }))
                  }
                  min={new Date(userWlProgram?.start_date)}
                  max={new Date(userWlProgram?.end_date)}
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='weight'>
                  Weight <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='number'
                  name='weight'
                  min={0}
                  max={1000}
                  step='any'
                  value={formState.weight}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='waterIntake'>
                  Yesterday's Water Intake{' '}
                  <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  name='waterIntake'
                  value={formState.waterIntake}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='sleepingHours'>
                  Hours Slept Last Night <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  name='sleepingHours'
                  value={formState.sleepingHours}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='bloodSugar'>
                  Today's Blood Sugar (if applicable):
                </label>
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={1000}
                  step='any'
                  name='bloodSugar'
                  value={formState.bloodSugar}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group mb-3'>
                <label htmlFor='bloodPressure'>
                  Today's Blood Pressure (if applicable):
                </label>
                <input
                  className='form-control'
                  name='bloodPressure'
                  value={formState.bloodPressure}
                  onChange={handleChange}
                />
              </div>
              {/* New Input Field for Additional Food / Notes */}
              <div className='form-group mb-3'>
                <label htmlFor='additionalNotes'>
                  Additional Food / Notes:
                </label>
                <textarea
                  id='additionalNotes'
                  name='additionalNotes'
                  className='form-control'
                  placeholder='Type additional food items here (if any)...'
                  value={formState.additionalNotes}
                  onChange={handleChange}
                />
              </div>
              {options?.length !== 0 ? (
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='meals-content'
                      id='meals-header'
                    >
                      <Typography>Log Meals</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.meals || 0)
                        .fill('')
                        .map((_, index) => (
                          <div
                            className='form-group mb-3'
                            key={`meal-${index}`}
                          >
                            <label htmlFor={`meal-${index}`}>
                              Log Meal {index + 1}:
                            </label>
                            <Select
                              isMulti
                              value={
                                (formState.meals && formState.meals[index]) ||
                                []
                              }
                              onFocus={handleFocus}
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, true)
                              }
                              placeholder='Start Typing...'
                              options={options}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className='mt-3'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='snacks-content'
                      id='snacks-header'
                    >
                      <Typography>Log Snacks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.snacks || 0)
                        .fill('')
                        .map((_, index) => (
                          <div
                            className='form-group mb-3'
                            key={`snack-${index}`}
                          >
                            <label htmlFor={`snack-${index}`}>
                              Log Snack {index + 1}:
                            </label>
                            <Select
                              isMulti
                              value={
                                (formState.snacks && formState.snacks[index]) ||
                                []
                              }
                              onFocus={handleFocus}
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, false)
                              }
                              placeholder='Start Typing...'
                              options={options}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : (
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='meals-content'
                      id='meals-header'
                    >
                      <Typography>Log Meals</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.meals || 0)
                        .fill('')
                        .map((_, index) => (
                          <div
                            className='form-group mb-3'
                            key={`meal-${index}`}
                          >
                            <label htmlFor={`meal-${index}`}>
                              Log Meal {index + 1}:
                            </label>
                            <CreatableSelect
                              isMulti
                              value={
                                (formState.meals && formState.meals[index]) ||
                                []
                              }
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, true)
                              }
                              placeholder='Type food name'
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className='mt-3'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='snacks-content'
                      id='snacks-header'
                    >
                      <Typography>Log Snacks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {new Array(currentPhase?.snacks || 0)
                        .fill('')
                        .map((_, index) => (
                          <div
                            className='form-group mb-3'
                            key={`snack-${index}`}
                          >
                            <label htmlFor={`snack-${index}`}>
                              Log Snack {index + 1}:
                            </label>
                            <CreatableSelect
                              isMulti
                              value={
                                (formState.snacks && formState.snacks[index]) ||
                                []
                              }
                              onChange={(items, action) =>
                                handleFoodIntake(items, action, index, false)
                              }
                              placeholder='Type food name'
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: PRIMARY_COLOR
                                }
                              })}
                            />
                          </div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeModal}>
            Close
          </Button>
          <Button
            variant='primary'
            type='button'
            onClick={isAllLogs ? handleEdit : handleDailyLog}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Deletion Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={cancelDelete}
        backdrop='static'
        keyboard={false}
        size='sm'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this log?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant='danger' onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <QuantityModal
        isOpen={isQuantityModalOpen}
        handleOpen={toggleQuantityModal}
        addQuantity={addQuantity}
        label={
          selectedOptions?.length > 0
            ? selectedOptions[selectedOptions.length - 1].label
            : 'Food Label'
        }
      />
    </>
  );
};

export default LogsEntryModal;
