import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { deleteUserWeightLossProgram, getUserWLP, StartUserWLP } from '../../../../actions/weight_loss';
import {
  UserWeightLossColumns,
  userWLPDropDownOptions
} from '../../../../helpers/weight_loss';
import DataGridMUI from '../../../common/DataGridMUI';
import StartUserWLPModal from './StartUserWLPModal';
import FilterSelect from '../../../common/FilterSelect';
import AreYouSureModal from '../../../common/AreYouSureModal';
import { Button } from 'react-bootstrap';

const UserWLP = props => {
  const { clinicId, csrfToken } = props;
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [userWLP, setUserWLP] = useState([]);
  const [selectedOption, setSelectedOption] = useState(userWLPDropDownOptions[0]);
  const [isSureModalOpen, setIsSureModalOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null)
  const [pageSize, setPageSize] = React.useState(20);

  const handleSureMoal = () => {
      setIsSureModalOpen(!isSureModalOpen);
  };

  const gotoConfigWLP = () => (window.location.href = 'configure');


  const handleDelete = () => {
    deleteUserWeightLossProgram(
      clinicId,
      setUserWLP,
      csrfToken,
      deleteRecordId
    );
    setDeleteRecordId(null)
    handleSureMoal();
  }

  const handleClose = () => {
    setShow(false);
    setSelectedPatient(null);
  };

  useEffect(() => {
    getUserWLP(clinicId, setUserWLP, selectedOption.value);
  }, [clinicId, selectedOption]);

  const handleStartProgram = () => {
    const startDate = new Date(date);
    const user_wl_program = {
      type: selectedOption.value,
      start_date: startDate,
      end_date: addDays(startDate, selectedPatient.total_days - 1)
    };
    StartUserWLP(
      user_wl_program,
      selectedPatient,
      csrfToken,
      setUserWLP,
      clinicId
    );
    handleClose();
  };

  return (
    <>
      <div className='flex-grow-1 container-p-y'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='font-weight-bold'>Client Weight Loss Program</h4>
            <div className='d-flex justify-content-between'>
              <FilterSelect
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                dropDownOptions={userWLPDropDownOptions}
              />
              <Button
                variant='btn btn-sm-secondary btn-primary btn-responsive'
                size='small'
                onClick={gotoConfigWLP}
              >
                Configure Weight Loss Plans
              </Button>
            </div>
          </div>
          <div className='card-body'>
            <DataGridMUI
              handlePageSizeChange={newPageSize => setPageSize(newPageSize)}
              pageSize={pageSize}
              list={userWLP}
              columns={UserWeightLossColumns(
                setShow,
                setSelectedPatient,
                setDeleteRecordId,
                handleSureMoal
              )}
            />
          </div>
        </div>
      </div>
      <StartUserWLPModal
        handleStartProgram={handleStartProgram}
        selectedPatient={selectedPatient}
        setDate={setDate}
        show={show}
        handleClose={handleClose}
        date={date}
      />
      <AreYouSureModal
        handleClose={handleSureMoal}
        open={isSureModalOpen}
        action={handleDelete}
      />
    </>
  );
};

export default UserWLP;
