// CampaignTemplate.jsx
import React, { useState, useCallback } from 'react';
import {
  faAngleDown,
  faAngleUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Modal } from 'react-bootstrap';
import { CUSTOM_CAMPAIGN } from '../../actions/actionTypes';
import DatePicker from './components/DatePicker.jsx';
import Editor from './Editor';
import TemplatesList from './templates/TemplatesList';
import { validateSize } from '../../helpers/template';

const CampaignTemplate = React.memo(
  ({
    title,
    subject,
    body,
    index,
    handleChange,
    handleRemoveClick,
    inputList,
    templatesList,
    handleTemplateUse,
    setSelectedIndex,
    handleDateChange,
    campaignType,
    setInputList,
    sendDate
  }) => {
    const [arrowIcon, setArrowIcon] = useState(faAngleDown);
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);

    const removeAttachment = useCallback(() => {
      setInputList(prevList => {
        const newList = [...prevList];
        newList[index].file = undefined;
        return newList;
      });
    }, [index, setInputList]);

    const toggleCollapse = useCallback(() => {
      setIsOpen(prev => {
        const newState = !prev;
        setArrowIcon(newState ? faAngleUp : faAngleDown);
        return newState;
      });
    }, []);

    const handleShow = useCallback(() => setShow(true), []);
    const handleClose = useCallback(() => setShow(false), []);

    return (
      <div className='card px-2 px-md-4 mb-3'>
        <div className='card-header'>
          <h4 className='font-weight-bold d-inline float-left mb-md-0 mb-2'>
            {inputList.length !== 1 && (
              <button
                className='btn btn-outline-danger mr-2 btn-responsive'
                onClick={() => handleRemoveClick(index)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
            Day {index + 1}
          </h4>
          <div className='float-right'>
            {campaignType === CUSTOM_CAMPAIGN && (
              <DatePicker
                handleChange={handleDateChange}
                index={index}
                sendDate={sendDate}
              />
            )}
            <button
              className='btn btn-sm-secondary btn-primary ml-2 btn-responsive'
              onClick={toggleCollapse}
            >
              <FontAwesomeIcon icon={arrowIcon} />
            </button>
          </div>
        </div>
        <Collapse in={isOpen}>
          <div className='card-body'>
            <div className='float-right'>
              {templatesList && (
                <button
                  className='btn btn-sm-secondary btn-primary mr-1 btn-responsive mb-2 mb-md-0'
                  onClick={() => {
                    handleShow();
                    setSelectedIndex(index);
                  }}
                >
                  Use Template
                </button>
              )}
              <label
                htmlFor={`file-upload-${index}`}
                className='btn btn-primary ml-0 ml-md-2 btn-responsive'
              >
                {inputList[index]?.file
                  ? inputList[index]?.file?.name ||
                    inputList[index]?.file?.filename
                  : 'Add Attachment'}
              </label>
              <input
                id={`file-upload-${index}`}
                hidden
                type='file'
                name='file'
                onChange={e => {
                  if (validateSize(e)) {
                    handleChange(e, index);
                  }
                }}
              />
              {inputList[index]?.file && (
                <button
                  className='btn btn-sm-secondary btn-primary ml-2'
                  onClick={removeAttachment}
                >
                  Remove Attachment
                </button>
              )}
            </div>
            <Editor
              title={title}
              subject={subject}
              body={body}
              index={index}
              handleChange={handleChange}
            />
          </div>
        </Collapse>
        <Modal show={show} onHide={handleClose} size='lg' className='p-3'>
          <Modal.Header closeButton>
            <Modal.Title>Templates</Modal.Title>
          </Modal.Header>
          <TemplatesList
            templates={templatesList}
            fromCampaign={true}
            handleTemplateUse={handleTemplateUse}
          />
        </Modal>
      </div>
    );
  }
);

export default CampaignTemplate;
