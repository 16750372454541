import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { showMeasurementEditForm } from '../../actions/measurements';
import NewMeasurementForm from './NewMeasurementForm';
import CircleIcon from '@mui/icons-material/Circle';

export class Measurements extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeRow: '' };
  }

  onRowClick = measurement => {
    this.props.onMeasurementRowClick(measurement);
    this.setState({ activeRow: measurement.id });
  };

  componentDidMount() {
    this.props.handleToggle(true);
  }

  componentWillUnmount() {
    this.props.handleToggle(false);
  }

  render() {
    const measurements = this.props.patient.measurements.map(measurement => {
      const activeRow =
        this.props.newMeasurementFormShow &&
        this.state.activeRow === measurement.id
          ? 'active-row'
          : '';

      const renderIndicator = className => {
        return (
          <CircleIcon style={{ fontSize: '12px' }} className={className} />
        );
      };

      return (
        <MeasurementRow
          key={measurement.id}
          onClick={() => this.onRowClick(measurement)}
          className={activeRow}
        >
          <td>
            <div className='d-flex align-items-center justify-content-start'>
              {measurement.supplemental_image_urls.length === 0 &&
                measurement.file_urls.length === 0 &&
                renderIndicator('indicator')}
              {measurement.supplemental_image_urls.length !== 0 &&
                renderIndicator('indicator indicator-supplement')}
              {measurement.file_urls.length !== 0 &&
                renderIndicator('indicator indicator-files')}

              {new Date(measurement.date).toLocaleDateString('en-US', {
                timeZone: 'UTC'
              })}
            </div>
          </td>
          <td>{measurement.neck}</td>
          <td>{measurement.chest}</td>
          <td>
            {measurement.left_arm}/{measurement.right_arm}
          </td>
          <td>{measurement.above_bb}</td>
          <td>{measurement.bellybutton}</td>
          <td>{measurement.below_bb}</td>
          <td>{measurement.hips}</td>
          <td>
            {measurement.left_thigh}/{measurement.right_thigh}
          </td>
          <td>
            {measurement.left_calf}/{measurement.right_calf}
          </td>
          <td>
            <TruncatedNotes title={measurement.notes || '-'}>
              {measurement.notes || '-'}
            </TruncatedNotes>
          </td>
        </MeasurementRow>
      );
    });

    const showForm = this.props.subscriptionActive;

    return (
      <>
        <div className='row pt-3 px-3'>
          <div className='col card'>
            <div className='card-body px-1 px-md-4'>
              {/* Wrap the table in a responsive container */}
              <div className='table-responsive'>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Neck</th>
                      <th>Chest</th>
                      <th>Arm</th>
                      <th>2" Above BB</th>
                      <th>Belly Button</th>
                      <th>2" Below BB</th>
                      <th>Hips</th>
                      <th>Thigh</th>
                      <th>Calf</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>{measurements}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showForm && (
          <div className='row mt-4'>
            <div className='col'>
              {this.props.newMeasurementFormShow && (
                <div className='card'>
                  <div className='card-body'>
                    <NewMeasurementForm
                      patient={this.props.patient}
                      isOwner={this.props.isOwner}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

const MeasurementRow = styled.tr`
  cursor: pointer;
`;

const TruncatedNotes = styled.span`
  display: inline-block;
  max-width: 150px; /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const mapDispatchToProps = dispatch => ({
  onMeasurementRowClick: measurement => {
    dispatch(showMeasurementEditForm(measurement));
  }
});

const mapStateToProps = state => ({
  newMeasurementFormShow: state.patientIndex.newMeasurementFormShow,
  isOwner: state.patientIndex.isOwner,
  subscriptionActive: state.patientIndex.subscriptionActive
});

export default connect(mapStateToProps, mapDispatchToProps)(Measurements);
