import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import placeholder from '../../../../app/assets/images/placeholder.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

// Styled component for consistent carousel image size
const CarouselImage = styled.img`
  width: 100%;
  height: 250px; /* Set a fixed height */
  object-fit: cover;
`;

// Styled container for the collapse content to enforce a minimum height
const CollapseContent = styled.div`
  min-height: 300px; /* Adjust this value as needed */
  padding: 1rem;
`;

const MeasurementSummary = props => {
  const measurement = props.measurement;

  // Map supplemental images to carousel items using the styled image component
  const supplementalImages = measurement.supplemental_image_urls.map(url => (
    <Carousel.Item key={url}>
      <CarouselImage src={url} alt='Supplemental' />
    </Carousel.Item>
  ));

  // Create the profile image carousel if an image URL is available; otherwise use the placeholder.
  const profileImage = measurement.profile_image_url ? (
    <Carousel
      controls={false}
      indicators={false}
      keyboard={false}
      slide={false}
      touch={false}
      interval={null}
    >
      <Carousel.Item>
        <CarouselImage src={measurement.profile_image_url} alt='Profile' />
      </Carousel.Item>
    </Carousel>
  ) : (
    <CarouselImage src={placeholder} alt='Profile placeholder' />
  );

  // State for collapse open/close
  const [open, setOpen] = useState(false);
  const [clientInfoIcon, setClientInfoIcon] = useState(faAngleDown);

  // Toggle collapse open state and switch the icon
  const handleArrow = () => {
    if (!open) {
      setClientInfoIcon(faAngleRight);
      setOpen(true);
    } else {
      setClientInfoIcon(faAngleDown);
      setOpen(false);
    }
  };

  return (
    <>
      {/* Measurement Details Card with Collapse */}
      <div className='card mt-3'>
        <div className='card-body'>
          <h5 style={{ display: 'inline' }}>
            {new Date(measurement.date).toLocaleDateString('en-US', {
              timeZone: 'UTC'
            })}
          </h5>
          <button className='btn btn-primary float-right' onClick={handleArrow}>
            <FontAwesomeIcon icon={clientInfoIcon} />
          </button>

          <Collapse in={open}>
            <CollapseContent>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Neck:</strong> {measurement.neck}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Chest:</strong> {measurement.chest}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Left Arm:</strong> {measurement.left_arm}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Right Arm:</strong> {measurement.right_arm}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Above Umbilicus:</strong> {measurement.above_bb}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Below Umbilicus:</strong> {measurement.below_bb}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Umbilicus:</strong> {measurement.bellybutton}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Hips:</strong> {measurement.hips}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Left Thigh:</strong> {measurement.left_thigh}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Right Thigh:</strong> {measurement.right_thigh}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Left Calf:</strong> {measurement.left_calf}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Right Calf:</strong> {measurement.right_calf}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Blood Pressure:</strong> {measurement.blood_pressure}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Weight:</strong> {measurement.weight}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>BMI:</strong> {measurement.bmi}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>% Body Fat:</strong> {measurement.body_fat}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>% Lean Muscle:</strong> {measurement.lean_muscle}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>% Water:</strong> {measurement.water}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-6 col-lg-9'>
                  <strong>Metabolic Age:</strong> {measurement.metabolic_age}
                </div>
                <div className='col-6 col-lg-9'>
                  <strong>Visceral Fat:</strong> {measurement.visceral_fat}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-12'>
                  <strong>Notes:</strong> {measurement.notes || 'N/A'}
                </div>
              </div>
            </CollapseContent>
          </Collapse>
        </div>
      </div>

      {/* Profile Images Card */}
      <div className='card mt-3 image-card'>
        <div className='card-body'>
          <div className='card-title'>
            <h5>
              Profile Images:{' '}
              {new Date(measurement.date).toLocaleDateString('en-US', {
                timeZone: 'UTC'
              })}
            </h5>
          </div>
          <div className='row mb-1 center-image'>
            <div className='col-12 col-lg-9 mt-3' style={{ width: 'inherit' }}>
              {profileImage}
            </div>
          </div>
        </div>
      </div>

      {/* Supplemental Images Card */}
      <div className='card mt-3 supplemental-card'>
        <div className='card-body'>
          <div className='card-title'>
            <h5>
              Supplemental Images:{' '}
              {new Date(measurement.date).toLocaleDateString('en-US', {
                timeZone: 'UTC'
              })}
            </h5>
          </div>
          <div className='row mb-1 center-image'>
            <div className='col-12 col-lg-9 mt-3' style={{ width: 'inherit' }}>
              {measurement.supplemental_image_urls.length !== 0 ? (
                <Carousel interval={null}>{supplementalImages}</Carousel>
              ) : (
                <CarouselImage
                  src={placeholder}
                  alt='Supplemental placeholder'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeasurementSummary;
