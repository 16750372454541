import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Material UI Components
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography
} from '@material-ui/core';
import { Alert } from '@mui/material';
import {
  FitnessCenter,
  ArrowBack,
  CardMembership,
  Message
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

// React-Bootstrap Components
import { Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select';

// Local Components & Helpers
import TableBodyCustom from './TableBody';
import DataGridMUI from '../common/DataGridMUI';
import WeightLossForm from '../weight_loss/components/Clinic/WeightLossForm';
import CampaignsList from '../campaigns/components/CampaignsList';
import CampaignNew from '../campaigns/CampaignNew';
import TemplateMessages from '../weight_loss/components/Clinic/TemplateMessages';
import { superAdminWeightLossTableColumns } from '../../helpers/weight_loss';
import { superAdminCampaignColumns } from '../../helpers/campaign';
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../actions/actionTypes';

const ClinicsListTable = ({
  clinics,
  csrfToken,
  weightLossProgramsList,
  campaigns
}) => {
  // State declarations
  const [clinicsList, setClinicsList] = useState(clinics);
  const [assignClinicsOptions, setAssignClinicsOptions] = useState([]); // For assign modal select
  const [campaignsList, setCampaignsList] = useState(campaigns);
  const [wlpList, setWlpList] = useState(weightLossProgramsList);

  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [campaignEmails, setCampaignEmails] = useState(null);
  const [activeCampaignTab, setActiveCampaignTab] = useState('campaigns'); // 'campaigns' or 'dripCampaigns'

  const [error, setError] = useState(null);
  const [wlpModalOpen, setWlpModalOpen] = useState(false);
  const [isEditingWlp, setIsEditingWlp] = useState(false);
  const [wlpData, setWlpData] = useState(null);
  const [wlpPhases, setWlpPhases] = useState([]);

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [selectedWlpId, setSelectedWlpId] = useState(null);
  const [selectedClinicId, setSelectedClinicId] = useState('');

  const [templateMessagesList, setTemplateMessagesList] = useState([]);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  // Fetch template messages from server
  const fetchTemplateMessages = async () => {
    try {
      const { data } = await axios.get('/super_admin/messages_list', {
        headers: { 'X-CSRF-Token': csrfToken }
      });
      setTemplateMessagesList(data.template_messages);
    } catch (err) {
      console.error('Error fetching template messages:', err);
      setError('Failed to fetch template messages.');
    }
  };

  const openMessageModal = async () => {
    await fetchTemplateMessages();
    setSelectedMessages([]);
    setMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setSelectedMessages([]);
  };

  // Fetch weight loss programs list
  const fetchWeightLossPrograms = async () => {
    try {
      const { data } = await axios.get(
        '/super_admin/weight_loss_program_list',
        {
          headers: { 'X-CSRF-Token': csrfToken }
        }
      );
      setWlpList(data.weightLossPrograms);
    } catch (err) {
      console.error('Error fetching weight loss programs:', err);
      setError('Failed to fetch weight loss programs.');
    }
  };

  // Handle change for clinic toggles
  const handleClinicToggleChange = async (event, clinic, index) => {
    const updatedClinic = {
      ...clinic,
      [event.target.name]: event.target.checked
    };

    try {
      const { data } = await axios.patch(
        `/${clinic.id}`,
        {
          scheduler_available: updatedClinic.scheduler_available,
          wlp_available: updatedClinic.wlp_available,
          initial_training_emails: updatedClinic.initial_training_emails
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
        }
      );
      const updatedList = [...clinicsList];
      updatedList[index] = updatedClinic;
      setClinicsList(updatedList);
      toastr.success('Clinic Updated');
    } catch (err) {
      const message = err.response?.data?.message || 'An error occurred';
      setError(message);
      toastr.error(message);
    }
  };

  // Open weight loss program modal
  const openWlpModal = () => {
    setWlpModalOpen(true);
  };

  // Close weight loss program modal and refresh list
  const handleWlpModalClose = () => {
    setIsEditingWlp(false);
    setWlpData(null);
    setCurrentCampaign(null);
    setWlpPhases([]);
    setWlpModalOpen(false);
    // Refresh the list after a delay (if needed)
    setTimeout(() => fetchWeightLossPrograms(), 2000);
  };

  // Duplicate a weight loss program for assignment to a clinic
  const duplicateWeightLossProgram = async wlpId => {
    try {
      const { data } = await axios.post(
        `/super_admin/duplicate_weight_loss_program/${wlpId}`,
        {},
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
          }
        }
      );
      setWlpList(data.weightLossPrograms);
      toastr.success('Weight Loss Program duplicated successfully');
    } catch (err) {
      console.error('Error duplicating weight loss program:', err);
      alert('Failed to duplicate weight loss program.');
    }
  };

  // Edit weight loss program data for editing
  const editWeightLossProgram = async programId => {
    try {
      const { data } = await axios.get(
        `/super_admin/edit_weight_loss_program/${programId}`,
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            Accept: 'application/json'
          }
        }
      );
      setWlpData(data.weightLossProgram);
      setWlpPhases(data.phases);
      setIsEditingWlp(true);
    } catch (err) {
      setError(
        err.response?.data?.message || 'Failed to load program for editing'
      );
    }
  };

  // Edit campaign data for editing
  const editCampaign = async campaignId => {
    try {
      const { data } = await axios.get(
        `/super_admin/edit_campaign/${campaignId}`,
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            Accept: 'application/json'
          }
        }
      );
      setCurrentCampaign(data.campaign);
      setCampaignEmails(data.emails);
      setActiveCampaignTab('dripCampaigns');
    } catch (err) {
      setError(
        err.response?.data?.message || 'Failed to load campaign for editing'
      );
    }
  };

  // Duplicate campaign logic
  const duplicateCampaign = async campaignId => {
    try {
      const { data } = await axios.post(
        `/super_admin/duplicate_campaign/${campaignId}`,
        {},
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
          }
        }
      );
      setCampaignsList(data.campaigns);
      toastr.success('Campaign duplicated successfully');
    } catch (err) {
      console.error('Error duplicating campaign:', err);
      toastr.error(
        err.response?.data?.message || 'Failed to duplicate campaign.'
      );
    }
  };

  // Open assign modal (for both WLP and messages)
  const openAssignModal = async (wlpId = null) => {
    try {
      const { data } = await axios.get('/super_admin/clinics_list', {
        headers: { 'X-CSRF-Token': csrfToken }
      });
      // Map clinics to react-select options
      const options = data.clinics.map(clinic => ({
        value: clinic.id,
        label: clinic.name
      }));
      setAssignClinicsOptions(options);
      // When opening from TemplateMessages, ensure message modal is closed
      setMessageModalOpen(false);
      setSelectedWlpId(wlpId);
      setAssignModalOpen(true);
    } catch (err) {
      console.error('Error fetching clinics:', err);
    }
  };

  const closeAssignModal = () => {
    setSelectedWlpId(null);
    setSelectedClinicId('');
    setAssignModalOpen(false);
  };

  // Assign either a duplicated WLP or selected messages to a clinic
  const handleAssignClinic = async () => {
    try {
      const isWlpAssignment = Boolean(selectedWlpId);
      const url = isWlpAssignment
        ? `/super_admin/duplicate_weight_loss_program/${selectedWlpId}?clinic_id=${selectedClinicId}`
        : '/super_admin/assign_messages_to_clinic';

      const payload = isWlpAssignment
        ? {} // For WLP assignment, no payload is needed as the ID is in the URL
        : {
            message_ids: selectedMessages,
            clinic_id: selectedClinicId
          };

      const { data } = await axios.post(url, payload, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json'
        }
      });

      if (isWlpAssignment) {
        setWlpList(data.weightLossPrograms);
        toastr.success('Weight Loss Program assigned successfully');
      } else {
        toastr.success('Messages assigned successfully');
      }
      closeAssignModal();
    } catch (err) {
      console.error('Error assigning:', err);
      toastr.error('Failed to assign.');
    }
  };

  // Columns configuration for the data grid (for weight loss programs)
  const columns = superAdminWeightLossTableColumns(
    setWlpList,
    csrfToken,
    null,
    duplicateWeightLossProgram,
    editWeightLossProgram,
    openAssignModal
  );

  // Fetch campaigns when campaign modal is open and the active tab is 'campaigns'
  useEffect(() => {
    if (campaignModalOpen && activeCampaignTab === 'campaigns') {
      const fetchCampaigns = async () => {
        try {
          const { data } = await axios.get('/super_admin/campaigns', {
            headers: { 'X-CSRF-Token': csrfToken }
          });
          setCampaignsList(data.campaigns);
          setCurrentCampaign(null);
          setCampaignEmails(null);
        } catch (err) {
          console.error('Error fetching campaigns:', err);
          setError('Failed to fetch campaigns.');
        }
      };
      fetchCampaigns();
    }
  }, [campaignModalOpen, activeCampaignTab, csrfToken]);

  // Handle tab switching in the campaign modal
  const handleCampaignTabChange = tabKey => {
    setActiveCampaignTab(tabKey);
    if (tabKey === 'campaigns') {
      setCurrentCampaign(null);
      setCampaignEmails(null);
    }
  };

  return (
    <>
      {/* Action Buttons */}
      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<FitnessCenter />}
        onClick={openWlpModal}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Weight Loss Programs
      </Button>

      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<CardMembership />}
        onClick={() => setCampaignModalOpen(true)}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Campaigns
      </Button>

      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<Message />}
        onClick={openMessageModal}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Messages
      </Button>

      {/* Weight Loss Program Modal */}
      <Dialog
        open={wlpModalOpen}
        onClose={handleWlpModalClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            {isEditingWlp ? (
              <IconButton
                edge='start'
                onClick={() => setIsEditingWlp(false)}
                aria-label='back'
              >
                <ArrowBack />
              </IconButton>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsEditingWlp(true)}
              >
                Create New Program
              </Button>
            )}
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleWlpModalClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isEditingWlp ? (
            <WeightLossForm
              campaignsList={campaignsList}
              csrfToken={csrfToken}
              weightLossProgram={wlpData}
              phases={wlpPhases}
              onClose={handleWlpModalClose}
            />
          ) : (
            <DataGridMUI list={wlpList} columns={columns} />
          )}
        </DialogContent>
      </Dialog>

      {/* Assign Modal (for both WLP and messages) */}
      <Dialog
        open={assignModalOpen}
        onClose={closeAssignModal}
        fullWidth
        maxWidth='xs'
      >
        <DialogContent>
          <Typography>Select a clinic to assign:</Typography>
          <Select
            options={assignClinicsOptions}
            onChange={selectedOption =>
              setSelectedClinicId(selectedOption?.value)
            }
            placeholder='Select a clinic'
            styles={{
              container: base => ({ ...base, marginTop: '16px' }),
              menu: base => ({ ...base, zIndex: 1300 }),
              menuPortal: base => ({ ...base, zIndex: 1300 })
            }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAssignModal} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleAssignClinic}
            color='primary'
            variant='contained'
            disabled={
              !selectedClinicId ||
              // Disable if neither messages nor a weight loss program ID is selected
              (selectedWlpId === null && selectedMessages.length === 0)
            }
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Campaign Modal */}
      <Dialog
        open={campaignModalOpen}
        onClose={() => setCampaignModalOpen(false)}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          <IconButton
            edge='end'
            color='inherit'
            style={{ float: 'right' }}
            onClick={() => setCampaignModalOpen(false)}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Tabs
              activeKey={activeCampaignTab}
              onSelect={k => handleCampaignTabChange(k)}
              unmountOnExit
            >
              <Tab eventKey='campaigns' title='Campaigns'>
                <CampaignsList
                  csrfToken={csrfToken}
                  campaigns={campaignsList}
                  columns={superAdminCampaignColumns(
                    setCampaignsList,
                    csrfToken,
                    editCampaign,
                    duplicateCampaign
                  )}
                />
              </Tab>
              <Tab
                eventKey='dripCampaigns'
                title='Informational campaigns'
                unmountOnExit
              >
                <CampaignNew
                  csrfToken={csrfToken}
                  campaignType={AUTOMATED_CAMPAIGN}
                  isSuperAdmin={true}
                  setActiveTab={setActiveCampaignTab}
                  currentCampaign={currentCampaign}
                  campaignEmails={campaignEmails}
                />
              </Tab>
            </Tabs>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Message Modal */}
      <Modal show={messageModalOpen} onHide={closeMessageModal} size='lg'>
        <Modal.Body className='p-0'>
          <TemplateMessages
            csrfToken={csrfToken}
            handleMessageSend={message => console.log('Message sent:', message)} // Replace with actual logic
            templateMessagesList={templateMessagesList}
            setTemplateMessagesList={setTemplateMessagesList}
            open={messageModalOpen}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
            handleAssignShow={() => openAssignModal(null)}
          />
        </Modal.Body>
      </Modal>

      {/* Clinics Table */}
      <TableContainer>
        {error && (
          <Alert severity='error' onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Table aria-label='clinics list table'>
          <TableHead>
            <TableRow>
              <TableCell>Clinic Id</TableCell>
              <TableCell>Clinic Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBodyCustom
            clinicsList={clinicsList}
            handleOnChange={handleClinicToggleChange}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ClinicsListTable;
