import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

const DailyLogCard = props => {
  const { dailyLog, open, setShowEdit, handleSelectedLog, handleDelete } =
    props;
  const [isOpen, setIsOpen] = useState(open);

  // Flatten meals and snacks arrays (assumes dailyLog.meals/snacks are objects with arrays)
  const mealItems = Object.values(dailyLog.meals).flat();
  const snackItems = Object.values(dailyLog.snacks).flat();

  const toggleCollapse = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className='card mt-3'>
      <div className='card-header d-flex justify-content-between align-items-center'>
        <h4 className='mb-0'>
          {new Date(dailyLog.date).toLocaleDateString('en-US')}
        </h4>
        <div>
          <button
            className='btn btn-sm btn-primary mr-2'
            onClick={() => {
              handleSelectedLog(dailyLog);
              setShowEdit(true);
            }}
            aria-label='Edit Daily Log'
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            className='btn btn-sm btn-danger mr-2'
            onClick={() => handleDelete(dailyLog.id)}
            aria-label='Delete Daily Log'
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            className='btn btn-sm btn-secondary'
            onClick={toggleCollapse}
            aria-expanded={isOpen}
            aria-label={isOpen ? 'Collapse details' : 'Expand details'}
          >
            <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
          </button>
        </div>
      </div>
      <Collapse in={isOpen}>
        <div className='card-body'>
          <ul className='list-unstyled mb-0'>
            <li>
              <strong>Weight:</strong> {dailyLog.weight}
            </li>
            <li>
              <strong>Yesterday's Water Intake:</strong> {dailyLog.water_intake}
            </li>
            <li>
              <strong>Hours Slept Last Night:</strong> {dailyLog.sleeping_hours}
            </li>
            <li>
              <strong>Blood Sugar:</strong> {dailyLog.blood_sugar}
            </li>
            <li>
              <strong>Blood Pressure:</strong> {dailyLog.blood_pressure}
            </li>
            <li>
              <strong>Foods List:</strong>
              {mealItems.length === 0 && snackItems.length === 0 ? (
                <span className='text-muted'> No food available!</span>
              ) : (
                <>
                  {mealItems.length > 0 && (
                    <>
                      <div className='mt-2'>
                        <strong>Meals:</strong>
                      </div>
                      <ul>
                        {mealItems.map((item, idx) => (
                          <li key={`meal-${idx}`}>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {snackItems.length > 0 && (
                    <>
                      <div className='mt-2'>
                        <strong>Snacks:</strong>
                      </div>
                      <ul>
                        {snackItems.map((item, idx) => (
                          <li key={`snack-${idx}`}>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              )}
            </li>
            {dailyLog.additional_notes && (
              <li className='mt-2'>
                <strong>Additional Notes:</strong> {dailyLog.additional_notes}
              </li>
            )}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default DailyLogCard;
