import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import { campaignListColumns } from '../../../helpers/campaign';
import CampaignStatsModal from './CampaignStatsModal';
import CampaignUsersModal from './CampaignUsersModal';

const CampaignsList = ({ columns, campaigns, clinicId, csrfToken }) => {
  const [campaignsList, setCampaignList] = useState([]);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [clientType, setClientType] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    setCampaignList(campaigns);
  }, [campaigns]);

  const handleStatsViewClick = id => {
    setCampaignId(id);
    setShowStatsModal(true);
  };

  const handleClientsListClick = id => {
    setCampaignId(id);
    setShowUsersModal(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        {/* Responsive container: adjusts height based on viewport */}
        <Box
          sx={{
            height: {
              xs: 'calc(100vh - 300px)',
              sm: 'calc(100vh - 250px)',
              md: 'calc(100vh - 200px)'
            },
            width: '100%'
          }}
        >
          <DataGrid
            rows={campaignsList}
            disableSelectionOnClick
            columns={
              columns ??
              campaignListColumns(
                setCampaignList,
                handleStatsViewClick,
                handleClientsListClick,
                setClientType,
                clinicId
              )
            }
            onPageSizeChange={newPageSize => setRowsPerPage(newPageSize)}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Paper>

      {showStatsModal && (
        <CampaignStatsModal
          clinicId={clinicId}
          show={showStatsModal}
          setShow={setShowStatsModal}
          campaignId={campaignId}
        />
      )}

      {showUsersModal && (
        <CampaignUsersModal
          csrfToken={csrfToken}
          clinicId={clinicId}
          show={showUsersModal}
          setShow={setShowUsersModal}
          campaignId={campaignId}
          clientType={clientType}
        />
      )}
    </Box>
  );
};

export default CampaignsList;
