// CampaignNew.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../actions/actionTypes';
import { createCampaign, getEmailFiles } from '../../actions/campaign';
import { getEndDate, getStartDate } from '../../helpers/campaign';
import TitleCard from '../common/TitleCard.jsx';
import CampaignTemplate from './CampaignTemplate';
import axios from 'axios';

const CampaignNew = ({
  csrfToken,
  redirect_url,
  clinicId,
  templatesList,
  campaignType,
  currentCampaign,
  campaignEmails,
  isSuperAdmin = false,
  setActiveTab = null
}) => {
  const [date] = useState(startOfDay(new Date()));
  const [inputList, setInputList] = useState(
    campaignEmails?.length > 0
      ? campaignEmails
      : [
          {
            day: 1,
            subject: null,
            body: null,
            send_date: campaignType === AUTOMATED_CAMPAIGN ? null : date,
            file: undefined
          }
        ]
  );
  const [title, setTitle] = useState(currentCampaign?.title);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Re-index days to keep them consecutive
  const reorderDays = useCallback(
    list => list.map((item, index) => ({ ...item, day: index + 1 })),
    []
  );

  useEffect(() => {
    if (!isSuperAdmin && currentCampaign) {
      getEmailFiles(clinicId, currentCampaign.id, setInputList);
    }
  }, [clinicId, currentCampaign, isSuperAdmin]);

  const handleInputChange = useCallback((e, index) => {
    setInputList(prevList => {
      const list = [...prevList];
      if (e.target?.name === 'file') {
        list[index][e.target.name] = e.target.files[0];
      } else if (e.target) {
        const { name, value } = e.target;
        list[index][name] = value;
      } else {
        list[index]['body'] = e;
      }
      return list;
    });
  }, []);

  const handleDateChange = useCallback((newDate, index) => {
    setInputList(prevList => {
      const list = [...prevList];
      list[index]['send_date'] = newDate;
      return list;
    });
  }, []);

  const handleTemplateUse = useCallback(
    (subject, body) => {
      setInputList(prevList => {
        const list = [...prevList];
        if (selectedIndex !== null && selectedIndex < list.length) {
          list[selectedIndex]['subject'] = subject;
          list[selectedIndex]['body'] = body;
        }
        return list;
      });
    },
    [selectedIndex]
  );

  const handleRemoveClick = useCallback(
    index => {
      setInputList(prevList =>
        reorderDays(prevList.filter((_, i) => i !== index))
      );
    },
    [reorderDays]
  );

  const handleAddClick = useCallback(() => {
    setInputList(prevList => [
      ...prevList,
      {
        day: prevList.length + 1,
        subject: null,
        body: null,
        send_date: campaignType === AUTOMATED_CAMPAIGN ? null : date,
        file: undefined
      }
    ]);
  }, [campaignType, date]);

  const handleCreateCampaign = useCallback(
    status => {
      // Ensure files are set to undefined if not attached
      const preparedInputList = inputList.map(item => ({
        ...item,
        file: item.file == null ? undefined : item.file
      }));

      const type =
        campaignType === AUTOMATED_CAMPAIGN
          ? AUTOMATED_CAMPAIGN
          : CUSTOM_CAMPAIGN;
      const totalDays = preparedInputList.length;
      const minDate = getStartDate(preparedInputList);
      const maxDate = getEndDate(preparedInputList);
      const basePath = isSuperAdmin ? '/super_admin' : `/${clinicId}`;
      const url = currentCampaign
        ? isSuperAdmin
          ? `${basePath}/update_campaign/${currentCampaign.id}`
          : `${basePath}/campaigns/${currentCampaign.id}`
        : isSuperAdmin
        ? `${basePath}/create_campaign`
        : `${basePath}/campaigns`;

      createCampaign(
        clinicId,
        csrfToken,
        redirect_url,
        preparedInputList,
        title,
        type,
        totalDays,
        minDate,
        maxDate,
        url,
        currentCampaign ? axios.put : axios.post,
        status,
        isSuperAdmin,
        setActiveTab
      );
    },
    [
      inputList,
      clinicId,
      csrfToken,
      redirect_url,
      title,
      campaignType,
      currentCampaign,
      isSuperAdmin,
      setActiveTab
    ]
  );

  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <TitleCard
          title={title}
          setTitle={setTitle}
          placeholder='Enter campaign name...'
        />
        {inputList
          .sort((a, b) => a.day - b.day)
          .map((x, i) => (
            <React.Fragment key={i}>
              <CampaignTemplate
                title={x.title}
                subject={x.subject}
                body={x.body}
                sendDate={x.send_date}
                index={i}
                campaignType={campaignType}
                setSelectedIndex={setSelectedIndex}
                templatesList={templatesList}
                handleRemoveClick={handleRemoveClick}
                inputList={inputList}
                handleTemplateUse={handleTemplateUse}
                handleDateChange={handleDateChange}
                handleChange={handleInputChange}
                setInputList={setInputList}
              />
              {inputList.length - 1 === i && (
                <div className='btn-box float-right'>
                  <button
                    className='btn btn-primary mt-2'
                    onClick={handleAddClick}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                  {!isSuperAdmin && (
                    <button
                      className='btn btn-primary mt-2 ml-2'
                      onClick={() => handleCreateCampaign(false)}
                    >
                      Save Campaign
                    </button>
                  )}
                  <button
                    className='btn btn-primary mt-2 ml-2'
                    onClick={() => handleCreateCampaign(true)}
                  >
                    {currentCampaign && currentCampaign.status
                      ? 'Update'
                      : 'Create Campaign'}
                  </button>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default CampaignNew;
